// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/zeit/3492de1f/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-homepage-tsx": () => import("/zeit/3492de1f/src/pages/homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-index-tsx": () => import("/zeit/3492de1f/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-references-tsx": () => import("/zeit/3492de1f/src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-services-tsx": () => import("/zeit/3492de1f/src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-society-tsx": () => import("/zeit/3492de1f/src/pages/society.tsx" /* webpackChunkName: "component---src-pages-society-tsx" */)
}

